<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Course focuses
			</div>
			<V2Button class="page__info--btn" to="/admin/focuses/create">
				Add new focus
			</V2Button>
		</div>

		<div>
			<table-view
				v-if="focuses"
				:table="getTable"
				:showSearch="false"
			/>
		</div>
	</div>
</template>

<script>

	import V2Button           from '@/components/v2/ui/V2Button';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Course focuses'
		},
		components: {
			V2Button,
			TableView
		},
		data: () => ({
			focuses: []
		}),
		computed: {
			getTable () {
				const headings = [
					'Focus'
				];
				if (!this.getFocuses) {
					return {
						headings
					};
				}
				return {
					headings,
					rows: this.getFocuses.map((focus) => {
						return {
							route: `/admin/focuses/${focus.id}/`,
							columns: [
								focus.title
							]
						};
					})
				};
			},
			getFocuses () {
				if (!this.focuses ||
						!this.focuses.length) {
					return false;
				}
				return this.focuses;
			},
			getAction () {
				return {
					text: 'Add new focus',
					route: '/admin/focuses/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/focuses',
						text: 'Focuses'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setFocuses();
		},
		methods: {
			async setFocuses () {
				const focuses = await api.admin.getAllFocuses();
				if (!focuses) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.focuses = focuses;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}

</style>
